import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import {
  HeroSection,
  UnderLine,
  Container,
  Button,
  ButtonLink,
} from "../components/global-style";
import AboutBg from "../img/images/about.jpg";

import logo2 from "../img/svg/tbwa-slash-yellow.svg";
import OurOffices from "../components/about/offices";
import styled from "styled-components";
import Avantages from "../components/about/avantages";
import Leadership from "../components/about/leadership";
import MediaQuery from "react-responsive";

const AboutText = styled.div`
  display: flex;
  & div:first-child {
    margin-right 20px;
  }
`;
const Couverture = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin: 0 20px;
  }
  img {
    width: 80px;
  }
  p {
    max-width: 400px;
  }
`;

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Helmet>
        <html lang="fr" />
        <title>A propos-TBWA \ DJAZ</title>
        <meta
          name="description"
          content="Depuis 15 ans, notre agence combine la méthodologie de la Disruption avec une connaissance intime des marchés et consomateurs nords-africains."
        />
          <meta property="og:image" content={`https://www.tbwadjaz.com${AboutBg}`} data-react-helmet="true" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="TBWA\ DJAZ" />
        <meta property="og:url" content="/http://tbwadjaz.com" />
      </Helmet>
      <HeroSection bgImage={AboutBg}>
        <Container>
          <h1>À PROPOS DE NOUS</h1>
          <UnderLine />
          <AboutText>
            <div>
              <h3 className="yellow">
                We Are The Disruption<sup>®</sup> Company{" "}
              </h3>
              <p>
                Nous aidons nos clients à créer de la différence. Nous créons
                des marques fortes. Nous enrichissons et protégeons les
                réputations.
              </p>
              <p>
                <p>
                  Fondée en 2003 à Alger, notre agence est affiliée depuis 2007
                  au collectif mondial TBWA, récemment nommé “Global Agency of
                  the Year” par le prestigieux magazine AdWeek
                </p>
              </p>
            </div>
            <MediaQuery query="(min-device-width: 800px)">
              <div>
                <p>
                  {" "}
                  Chez TBWA\ DJAZ, nous croyons que la communication n’est pas
                  un « luxe », mais un des piliers de la réussite en affaires.
                  Nous sommes des créateurs de différence, ce qui à son tour
                  crée de la valeur pour les entreprises comme pour les
                  consommateurs.
                </p>
                <p>
                  C'est pour cela que depuis plus de 15 ans, nous combinons la
                  puissance de la Disruption, la méthodologie phare du collectif
                  TBWA, avec notre connaissance intime des marchés et des
                  consommateurs nord-africains pour apporter de la valeur
                  tangible à nos clients.
                </p>
              </div>
            </MediaQuery>
          </AboutText>
        </Container>
      </HeroSection>

      <Container>
        <MediaQuery query="(max-width: 799px)">
          <div>
            <p>
              {" "}
              Chez TBWA\ DJAZ, nous croyons que la communication n’est pas un «
              luxe », mais un des piliers de la réussite en affaires. Nous
              sommes des créateurs de différence, ce qui à son tour crée de la
              valeur pour les entreprises comme pour les consommateurs.
            </p>
            <p>
              C'est pour cela que depuis plus de 15 ans, nous combinons la
              puissance de la Disruption, la méthodologie phare du collectif
              TBWA, avec notre connaissance intime des marchés et des
              consommateurs nord-africains pour apporter de la valeur tangible à
              nos clients.
            </p>
          </div>
        </MediaQuery>
        <p>
          TBWA\ DJAZ est une agence conseil en communication intégrée,
          c'est-à-dire qui combine une série de métiers sous un même toit. Nos
          équipes se composent au gré des projets, injectant les expertises
          nécessaires là et au moment où elles apportent de la valeur, le tout
          coordonné par un seul point de contact.
        </p>
        <h1>Notre différence</h1>
        <UnderLine />
        <Avantages />
      </Container>

      <Container>
        <Couverture>
          <div>
            <img src={logo2} />
          </div>
          <div>
            <span>Nous couvrons l’Afrique du Nord</span>
            <p>
              TBWA\Djaz sert de hub régional, assure la liaison avec le client
              et gère les équipes dans le pays en fonction des besoins.
            </p>
          </div>
        </Couverture>
      </Container>
      <OurOffices />
      <Leadership />
      <div style={{ margin: "1rem auto", width: "10rem" }}>
        <ButtonLink to="/carriere">NOUS REJOINDRE</ButtonLink>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
