import React from "react";
import styled from "styled-components";
import Backslash from "../../img/svg/backslash-yellow.png";
import { Container } from "../global-style";

const Advantages = styled.div`
  display: flex;
  align-items: center;
`;
const SlashImage = styled.img`
  height: ${props => props.slashHight};
  margin-right: 0.5rem;
  @media (max-width: 800px) {
    display: none;
  }
`;
const TbwaText = styled.div`
  -webkit-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
  font-size: 1rem;
  @media (max-width: 800px) {
    -webkit-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    width: 100%;
    transform: skewX(0deg);
    span {
      display: inline-block;
      transform: skewX(0deg);
      -webkit-transform: skewX(0deg);
      -ms-transform: skewX(0deg);
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  span {
    display: inline-block;
    transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
  }
`;

class TbwaBullets extends React.Component {
  constructor() {
    super();
    this.avantagesref = React.createRef();
    this.state = {
      slashHight: 0
    };
  }
  componentDidMount() {
    this.setState({
      slashHight: this.avantagesref.current.clientHeight
    });
  }

  render() {
    return (
      <Container>
        <Advantages ref={this.avantagesref}>
          <SlashImage
            src={Backslash}
            slashHight={`${this.state.slashHight}px`}
          />
          <TbwaText>
            <ul>
              {this.props.data.map((item, index) => {
                return (
                  <li>
                    {item.split(" ").map(listItem => {
                      return <span>{listItem} &nbsp;</span>;
                    })}
                  </li>
                );
              })}
            </ul>
          </TbwaText>
        </Advantages>
      </Container>
    );
  }
}
export default TbwaBullets;
