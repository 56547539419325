import React from "react";
import TbwaBullets from "../common/TbwaBullets";

const data = [
  "Une agence de conseil en communication pluridisciplinaire à fort impact avec des solutions créatives pour ses clients en Algérie et en Afrique du Nord",
  "Une connaissance approfondie de la culture et des environnements algérien et nord-africain associée à des processus et des outils créatifs aux normes internationales.",
  "Une capacité éprouvée pour le développement de contenu créatif.",
  "Une attitude “anti-maalich” : un dévouement à l’excellent travail et l’attention aux détails."
];
const Avantages = props => {
  return <TbwaBullets data={data} />;
};
export default Avantages;
