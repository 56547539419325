import React from 'react'
import alexandre from '../../img/leadership/alexandre.png'
import bechar from '../../img/leadership/bechar.png'
import mourad from '../../img/leadership/mourad.png'
import sidali from '../../img/leadership/sidali.png'
import rymel from '../../img/leadership/rymel.png'
import zakaria from '../../img/leadership/zakaria.png'

export const leaders = [
  {
    name: 'Alexandre Beaulieu',
    position: 'General Manager',
    bio:
      "Alexandre a plus de 15 ans d'expérience, ayant vécu et travaillé dans 7 pays. Il a travaillé sur des campagnes de publicité et de relations publiques pour des sociétés telles que Microsoft, Nissan, Nokia, Henkel, Lafarge, Danone, BNP Paribas, Cisco, KPMG et GE.",
    social: {
      linkedIn: 'https://www.linkedin.com/in/beaulieualexandre/',
      gmail: 'alexandre@tbwadjaz.com',
    },
    image: <img src={alexandre} />,
  },
  {
    name: 'Abdelhamid BECHAR',
    position: 'Chief Operations Officer',
    bio:
      "Hamid a rejoint TBWA  DJAZ en 2008 au sein de l'équipe de production. Il est rapidement devenu chef du département avant d'endosser une autre responsabilité importante en tant que gestionnaire de trafic.",
    social: {
      linkedIn: 'https://www.linkedin.com/in/abdelhamid-bechar-8b872b10a/',
      gmail: 'hamid@tbwadjaz.com',
    },
    image: <img src={bechar} />,
  },
  {
    name: 'Sidali Chetouh ',
    position: 'Creative Director',
    bio:
      'Sidali cumule plus de 8 années d’expérience dans la publicité, notamment pendant 6 ans chez JWT Algérie où il a acquis une vaste expérience dans les secteurs de la téléphonie mobile et de l’automobile.Sidali est diplômé de l’Institut National des Arts Graphiques d’Alger (INSIAG).',
    social: {
      linkedIn: 'https://www.linkedin.com/in/sidali-chetouh-3484b520/',
      gmail: 'sidali@tbwadjaz.com ',
    },
    image: <img src={sidali} />,
  },
  {
    name: 'Rymel Laroui ',
    position: 'Strategic Planning Director',
    bio:
      "Rymel a débuté sa carrière en tant que responsable de la publicité chez Euro RSCG (aujourd'hui Havas) à Alger, où elle a travaillé pour Peugeot, Mobilis et Hamoud Boualem. Elle a ensuite rejoint TBWA  DJAZ en 2008. Elle a rapidement été promue et occupe désormais le poste de directrice du département planning stratégique. Rymel apporte une vaste expérience dans la gestion de projets complexes pour des clients leaders de leurs secteurs. Rymel est diplômé en marketing de eHEC Alger. Elle parle anglais, arabe et français.",
    social: {
      linkedIn: 'https://www.linkedin.com/in/rymel-laroui-43957719/',
      gmail: 'rymel@tbwadjaz.com',
    },
    image: <img src={rymel} />,
  },
  {
    name: 'Zakaria Zareb',
    position: 'Experiential Marketing Director',
    bio:
      "Après ses études, Zakaria a rejoint la SAS (Société Algérienne des Spectacles) en tant que chef de projet dans les relations publiques, puis l’événementiel et producteur exécutif en dernier lieu. Zakaria a rejoint TBWA  DJAZ en 2013, en tant que chef de projet au sein du département événementiel pour devenir aujourd'hui directeur du pôle marketing expérientiel après avoir été chef de file pour de nombreux projets transversaux et complexes. Zakaria est titulaire d'un diplôme d'études supérieures en gestion des ventes et marketing de l'Institut supérieur de gestion et de planification à Alger.Il parle anglais, arabe et français.",
    social: {
      linkedIn: 'https://www.linkedin.com/in/zakaria-zareb/',
      gmail: 'zakaria.zareb@tbwadjaz.com',
    },
    image: <img src={zakaria} />,
  },
]
