import React from "react";
import styled from "styled-components";
import Algerie from "../../img/images/algerie.jpg";
import Maroc from "../../img/images/maroc.jpg";
import Tunisie from "../../img/images/tunisie.jpg";
import DjazzLogo from "../../img/tbwa-djazz.svg";
import AlifLogo from "../../img/tbwa-alif.svg";
import PointCarreLogo from "../../img/tbwa-point-carre.svg";

const Container = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const CountryBox = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(${props => props.bgImage});
  background-size: cover;
  background-position: top;
  color: white;
  width: 33.33%;
  padding: 100px;
  img{
    width: 200px;
  }
  p {
    font-size: 0.5rem;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const OurOffices = props => {
  return (
    <Container>
      <CountryBox bgImage={Maroc}>
        <h3>MAROC</h3>
        <img src={AlifLogo} />
        <p>
          ALIF est un pionnier de la publicité au Maroc et offre de solides
          capacités de planification et d’exécution.
        </p>
      </CountryBox>
      <CountryBox bgImage={Algerie}>
        <h3>ALGERIE</h3>
        <img src={DjazzLogo} />
        <p>
          Djaz est une agence intégrée de premier plan et est le pionnier du RP
          en Algérie. Elle sert de Hub pour TBWA, Ketchum et les autres agences
          ominicom dans la région Nord Afrique.
        </p>
      </CountryBox>
      <CountryBox bgImage={Tunisie}>
        <h3>TUNISIE</h3>
        <img src={PointCarreLogo} />

        <p>
          Pointcarré dispose d'une équipe très compétente, capable de fournir
          des conseils stratégiques ainsi que de gérer sur le terrain et
          d'exécuter avec perfection.
        </p>
      </CountryBox>
    </Container>
  );
};
export default OurOffices;
