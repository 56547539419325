import React from 'react'
import { UnderLine, Container } from '../global-style'
import styled from 'styled-components'
import { leaders } from './utils'

const LeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
`

const LeaderBox = styled.div`
  width: 200px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  h5,
  h6 {
    font-weight: normal;
    margin: 0.2rem 0;
  }

  p {
    font-size: 0.6rem;
  }
`

const Leadership = props => {
  return (
    <Container>
      <h1>Leadership</h1>
      <UnderLine />
      <LeaderContainer>
        {leaders.map((leader, index) => {
          return (
            <LeaderBox>
              {leader.image}
              <h5>{leader.name}</h5>
              <h6>{leader.position}</h6>
              <p>{leader.bio}</p>
            </LeaderBox>
          )
        })}
      </LeaderContainer>
    </Container>
  )
}

export default Leadership
